import "../CSS/footer.css";

const Footer = () => {
    return (
        <div className='Footer'>
                <h6 className='Middle-footer'>Pet Care Purrsuit ©2023</h6>
        </div>
    )
}

export default Footer